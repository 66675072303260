import { IconProps } from '@/shared/props';
import { U1Icon } from '@/assets/images/icons';
import { PAGES } from '../consts';
import { Icon } from './Icon';
import { useTranslation } from 'react-i18next';
import { StyledLink } from '../styles';

export const U1Logo: React.FC<IconProps> = ({ $size, $top, $autofocus }: IconProps) => {
  const logoSize = $size ?? 35;
  const focusProp = $autofocus ? { 'data-u1-focus-to': true } : {};
  const { t } = useTranslation();
  return (
    <StyledLink href={PAGES.SHARED.DEFAULT} aria-label={t('header.u1_logo')}>
      <Icon
        dataTestId="u1Logo"
        $top={$top}
        $size={logoSize}
        $src={U1Icon}
        $alt={t('header.u1_logo')}
        {...focusProp}
      />
    </StyledLink>
  );
};
