import styled, { createGlobalStyle, css } from 'styled-components';
import { ConfirmPopupCss } from './shared/components/ConfirmPopup/ConfirmPopup.styles';
import { GlobalScrollbarCss } from './shared/components/Scrollbar/U1Scrollbar.styles';
import { GlobalFonts } from './globalFonts';
import { simpleSelectStyles } from './shared/components/SimpleSelect/SimpleSelect.styles';
import { datepickerGlobalCss } from './shared/components/Datepicker/Datepicker.styles';

const DropdownStyle = css`
  .ant-select-dropdown {
    background: ${(props) => props.theme.inputColor};
    border-radius: 5px;
  }

  .ant-select-dropdown:not(.simple-select) {
    height: 200px;
  }

  .ant-select-item,
  .ant-select-selection-item {
    color: ${(props) => props.theme.textColor} !important;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled),
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: ${(props) => props.theme.selectedButtonBG};
  }
`;

const notificationStyle = css`
  .ant-notification {
    top: 95px !important;
  }

  .ant-notification-notice-with-icon {
    gap: 16px;
    display: flex;
    flex-direction: row;
  }

  .ant-notification-notice-icon {
    position: relative;
    align-items: center;
    display: flex;
  }

  .ant-notification-notice-message {
    display: flex;
    margin: 0px !important;
    width: 100%;
  }

  .ant-notification-notice-message, .ant-notification-notice-description {
    color ${(props) => props.theme.secondaryTextColor};
    font-size: 13px !important;
    font-weight: 400 !important;
  }

  .anticon{
    color:  ${(props) => props.theme.textColor};
  }

  .ant-notification-notice {
    padding: 20px;
    background ${(props) => props.theme.cardsBgColor};
    border-radius: 8px;
  }
`;

const antdGlobalCss = css`
  ${(props) =>
    !props.theme.isMobile &&
    `.ant-modal-mask {
    backdrop-filter: blur(10px);
  }`}

  .ant-dropdown {
    z-index: 100;
  }

  .ant-dropdown-menu {
    background: ${(props) => props.theme.cardsColor};
    border: 1px solid ${(props) => props.theme.marginsColor};
    border-radius: 5px;
    padding: 10px;
  }

  .user-dropdown {
    width: 283px;

    ul {
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 20px;
      justify-content: space-between;
    }

    li {
      padding: 0px;
    }

    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover,
    .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
    .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
    .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
    .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
      background: none;
    }

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      cursor: auto;
    }
  }

  .ant-menu-submenu-popup > .ant-menu {
    background-color: ${(props) => props.theme.cardsBgColor} !important;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-menu-item:active,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent !important;
  }

  .ant-menu-item.main-menu-item {
    margin: 0px !important;
    padding: 0px !important;
    padding-left: 5px !important;
    width: 127px !important;
  }

  #main-menu-company-popup .ant-menu-item:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.marginsColor};
  }

  #main-menu-company-popup .ant-menu-item:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.marginsColor};
  }

  &[dir='rtl'] .ant-menu-item {
    text-align: right;
  }

  ${ConfirmPopupCss}
  ${DropdownStyle}
  ${notificationStyle}
  ${GlobalScrollbarCss}
  ${simpleSelectStyles}
  ${datepickerGlobalCss}
`;

export const GlobalStyle = createGlobalStyle`
${GlobalFonts}
${antdGlobalCss}

*:focus-visible, *:focus-visible .u1st-focused{
  outline: 3px solid ${(props) => props.theme.a11yBorder} !important;
  border-radius: 5px;
  box-shadow: none !important;
}

.u1st-sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

html[dir='rtl'] * {
  font-family: system-ui !important;
}

html,
  body {
    margin: 0;
    padding: 0;
    background: #212121;
    font-family: 'Poppins';
    overflow-x: hidden;
  }

  #root {
    margin: 0;
    padding: 0;
    font-weight: 600;
    letter-spacing: 0.5px;
    background: #212121;
  }


`;

export const StyledApp = styled.div`
  width: 100%;
  height: 100%;
`;
