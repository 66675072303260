import { Route, Routes } from 'react-router-dom';
import { StyledMainLoggedIn } from '../Main.styles';
import { DealsSection } from './Panels/Deals/Deals';
import { PAGES } from '@/shared/consts';
import { SettingsSection } from './Panels/Settings/Settings';
import { StatsSection } from './Panels/Stats/Stats';
import { supportedLanguages } from '@/stores/LangStore/LangStore.consts';

export const VendorView: React.FC = () => {
  return (
    <StyledMainLoggedIn>
      <Routes>
        {/* lang routes */}
        {supportedLanguages.map((lang) => (
          <Route key={lang.code} path={`/${lang.code}`}>
            <Route path={PAGES.SHARED.DEFAULT.replace('/', '')} element={<DealsSection />} />
            <Route path={PAGES.AFTER_LOGIN.DEALS.replace('/', '')} element={<DealsSection />} />
            <Route path={PAGES.AFTER_LOGIN.STATS.replace('/', '')} element={<StatsSection />} />
            <Route
              path={PAGES.AFTER_LOGIN.SETTINGS.replace('/', '')}
              element={<SettingsSection />}
            />
          </Route>
        ))}
        {/* default routes */}
        <Route path={PAGES.SHARED.DEFAULT} element={<DealsSection />} />
        <Route path={PAGES.AFTER_LOGIN.DEALS} element={<DealsSection />} />
        <Route path={PAGES.AFTER_LOGIN.STATS} element={<StatsSection />} />
        <Route path={PAGES.AFTER_LOGIN.SETTINGS} element={<SettingsSection />} />
      </Routes>
    </StyledMainLoggedIn>
  );
};
