import 'antd/dist/antd.css';
import { StyledApp } from './globalStyles';
import { useAuth } from './hooks/useAuth';
import { useChatBot } from './hooks/useChatBot';
import { useRedirect } from './hooks/useRedirect';
import { Loading } from './shared/components/Loading/Loading';
import { useAuthStore } from './stores/AuthStore';
import { LOGIN_STATE } from './stores/Stores.consts';
import { AfterLogin } from './Views/AfterLogin/AfterLogin';
import { BeforeLogin } from './Views/BeforeLogin/BeforeLogin';
import { HelmetProvider } from 'react-helmet-async';
import { MetaTags } from './shared/seo';
import { Suspense, useEffect } from 'react';
import { setU1Configuration } from '@u1/react-a11y-hooks';
import i18n from './services/localization';
import { I18nextProvider } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLangStore } from './stores/LangStore/LangStore';

export const App = () => {
  useChatBot();
  const loginState = useAuthStore((state) => state.loginState);
  const langStore = useLangStore();
  const { lang } = useParams();
  const { loadingAuth } = useAuth();
  const { loadingToken } = useRedirect();

  // Lang
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
    document.documentElement.setAttribute('dir', langStore.getLangDir() ?? 'ltr');
  }, []);

  // Accessibility
  useEffect(() => {
    setU1Configuration({
      visualFocus: {
        style: {
          color: 'white',
        },
      },
    });
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<Loading />}>
        <HelmetProvider>
          <MetaTags />
          <StyledApp className="App">
            {loadingAuth || loadingToken || loginState === LOGIN_STATE.UNINITIATED ? (
              <Loading />
            ) : loginState === LOGIN_STATE.LOGGED_IN ? (
              <AfterLogin />
            ) : (
              <BeforeLogin />
            )}
          </StyledApp>
        </HelmetProvider>
      </Suspense>
    </I18nextProvider>
  );
};
