import React from 'react';
import { StyledProjectsListCard, StyledProjectsListWrapper } from '../ProjectsPage.style';
import { ProjectsListItem } from './ProjectsListItem';
import { ProjectsListProps } from '../../projects.consts';

export const ProjectsList: React.FC<ProjectsListProps> = ({ projectsList }: ProjectsListProps) => {
  return (
    <StyledProjectsListCard>
      <StyledProjectsListWrapper role="list">
        {projectsList &&
          projectsList.map((el, index) => (
            <ProjectsListItem key={index} projectsListItem={el}></ProjectsListItem>
          ))}
      </StyledProjectsListWrapper>
    </StyledProjectsListCard>
  );
};
