import { Icon } from '@/shared/components/Icon';
import { StyledPinkRouterLink } from '@/shared/components/Link/Links.styles';
import { PAGES, USER_ROLE } from '@/shared/consts';
import { StyledDivider, StyledOrangeGradText } from '@/shared/styles';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import React, { useState, useEffect } from 'react';
import {
  StyledProjecListItemWrapper,
  IconFrameWrapper,
  StyledProjecListItemRightSideWrapper,
  StyledProjecListItemDetailsWrapper,
  StyledProjecListItemName,
  StyledProjecListItemDomain,
  StyledProjecListItemLinkesWrapper,
  StyledFreeTextWrapper,
  StyledRightSectionItems,
} from '../ProjectsPage.style';
import { U1Link } from '@u1/react-a11y-hooks';
import { COLORS } from '@/shared/colors';
import { useUserStore } from '@/stores/UserStore';
import { ProjectsListItemProps } from '../../projects.consts';
import { useTranslation } from 'react-i18next';
import { useLangStore } from '@/stores/LangStore/LangStore';

export const ProjectsListItem: React.FC<ProjectsListItemProps> = ({ projectsListItem }) => {
  const subscription = useSubscriptionStore();
  const userRole = useUserStore((state) => state.role);
  const [domainOrAppidContent, setDomainOrAppidContent] = useState('');
  const { t } = useTranslation();
  const isRtl = useLangStore((state) => state.isRtl);

  useEffect(() => {
    if (projectsListItem.domain)
      setDomainOrAppidContent(`${t('projects.projects_list.domain')}: ${projectsListItem.domain}`);
    else if (projectsListItem.appId)
      setDomainOrAppidContent(`Application ID: ${projectsListItem.appId}`);
  }, [projectsListItem]);

  return (
    <StyledProjecListItemWrapper key={projectsListItem.id} role="listitem">
      <IconFrameWrapper data-testid="projectFrameworkIcon" $isRtl={isRtl()}>
        <Icon $size={32} $src={projectsListItem.frameworkIcon} $alt={projectsListItem.framework} />
      </IconFrameWrapper>
      <StyledProjecListItemRightSideWrapper $isRtl={isRtl()}>
        <StyledProjecListItemDetailsWrapper>
          <StyledProjecListItemName data-testid="projectName" role="heading" aria-level={2}>
            {projectsListItem.name}
          </StyledProjecListItemName>
          <StyledDivider $color={COLORS.LIGHT_PURPLE_GRAY} />
          <StyledProjecListItemDomain data-testid="domainName">
            {domainOrAppidContent}
          </StyledProjecListItemDomain>
        </StyledProjecListItemDetailsWrapper>
        <StyledRightSectionItems>
          <StyledFreeTextWrapper>
            {subscription.isFreemium && (
              <StyledOrangeGradText fontSize="16" fontWeight={600}>
                {t('projects.projects_list.free')}
              </StyledOrangeGradText>
            )}
          </StyledFreeTextWrapper>
          {(subscription.isActive || userRole === USER_ROLE.ENTERPRISE) && (
            <StyledProjecListItemLinkesWrapper>
              <U1Link>
                <StyledPinkRouterLink
                  to={`${PAGES.AFTER_LOGIN.DOCS.replace(':projectIdParam', projectsListItem.id)}`}
                  data-testid="openDocsButton"
                  data-u1-link="link"
                >
                  {t('projects.projects_list.docs')}
                </StyledPinkRouterLink>
              </U1Link>
              <U1Link>
                <StyledPinkRouterLink
                  to={`${PAGES.AFTER_LOGIN.CONFIGURATION.replace(
                    ':projectIdParam',
                    projectsListItem.id
                  )}`}
                  data-testid="viewConfigurationButton"
                  data-u1-link="link"
                >
                  {t('projects.projects_list.config')}
                </StyledPinkRouterLink>
              </U1Link>
            </StyledProjecListItemLinkesWrapper>
          )}
        </StyledRightSectionItems>
      </StyledProjecListItemRightSideWrapper>
    </StyledProjecListItemWrapper>
  );
};
