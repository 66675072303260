export type LangDto = {
  id: string;
  code: string;
  name: string;
  active: boolean;
  direction: 'rtl' | 'ltr';
};

export type AvailableLang = {
  value: number;
  label: string;
  code: string;
  direction: 'rtl' | 'ltr';
};

export type LangStore = {
  availableLangs: AvailableLang[];
  getLanguage: (value: string) => string | undefined;
  getAllLanguages: () => void;
  isLangSupported: () => boolean;
  getSelectedLang: () => string;
  getLangDir: () => 'rtl' | 'ltr' | undefined;
  isRtl: () => boolean;
};

export const supportedLanguages: AvailableLang[] = [
  { value: 1, label: 'English', code: 'en', direction: 'ltr' },
  { value: 2, label: 'Hebrew', code: 'he', direction: 'rtl' },
];
