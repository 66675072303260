import { StyledRow } from '@/shared/styles';
import styled from 'styled-components';

export const StyledLeftSection = styled(StyledRow)`
  justify-content: space-between;
  margin-left: 20px;
`;

export const StyledRightSection = styled(StyledRow)`
  justify-content: space-between;
  gap: 40px;
`;
