import { U1FeatureExampleImg } from '@/assets/images/backgrounds';
import { StyledCol, StyledText, StyledOrangeGradTitle, StyledTitle } from '@/shared/styles';
import React from 'react';
import {
  StylecU1FeaturesExampleContainer,
  StyledFeatures,
  StyledSectionContainer,
  StyledFeaturesContainer,
  StyledTopDescription,
  StyledMainSectionsText,
} from '../MainPage.styles';
import { isMobile } from 'react-device-detect';
import { Icon } from '@/shared/components/Icon';
import { useTranslation } from 'react-i18next';

export const U1Features: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledSectionContainer>
      <StyledTopDescription>
        <StyledOrangeGradTitle level={2}>{t('homepage.features.title')}</StyledOrangeGradTitle>
        <StyledMainSectionsText>{t('homepage.features.content')}</StyledMainSectionsText>
      </StyledTopDescription>
      <StylecU1FeaturesExampleContainer>
        <StyledFeaturesContainer>
          <StyledCol>
            <Icon
              $alt={t('homepage.features.alt')}
              $src={U1FeatureExampleImg}
              $height={isMobile ? 203 : 303}
              $width={isMobile ? 295 : 436}
              $loading="lazy"
            />
          </StyledCol>
          <StyledFeatures>
            <StyledTitle level={3} $size={18}>
              {t('homepage.library.title')}
            </StyledTitle>
            <StyledText fontSize="16px" fontWeight={400} textAlign="start">
              {t('homepage.library.content')}
            </StyledText>
          </StyledFeatures>
        </StyledFeaturesContainer>
      </StylecU1FeaturesExampleContainer>
    </StyledSectionContainer>
  );
};
