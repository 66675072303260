import styled from 'styled-components';
import {
  StyledCard,
  StyledCol,
  StyledLeftSection,
  StyledRow,
  StyledRightSection,
} from '@/shared/styles';
import { FrameworkCardProps } from '@/shared/props';
import { FormError } from '@/shared/components/Forms/FormError';

export const StyledNewProjectFormCard = styled(StyledCard)`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 24px;
  button {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const StyledFormRow = styled(StyledRow)`
  width: 100%;
`;

export const StyledFormLeftSection = styled(StyledLeftSection)`
  width: 100%;
  align-items: start;
`;
export const StyledFormRightSection = styled(StyledRightSection)`
  justify-content: flex-start;
  width: 100%;
  margin-top: 18px;
`;

export const StyledFormHorizontalDivider = styled.div`
  border-left: 1px solid ${(props) => props.theme.marginsColor};
  height: 200px;
`;
export const StyledFormHorizontalDividerWrapper = styled.div`
  display: flex;
  padding: 0 32px;
`;

export const StyledFrameworkCard = styled(StyledCard)<FrameworkCardProps>`
  background: ${(props) =>
    props.$isCurrent ? props.theme.gradientBorder : props.theme.inputBorder}; /*border color */
  height: ${(props) => props.$height ?? `fit-content`};
  width: ${(props) => props.$width ?? `fit-content`};
  display: inline-block;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 1px;
  ${(props) => !props.$isCurrent && 'cursor: pointer'};
  &:hover {
    background: ${(props) =>
      props.$isCurrent ? props.theme.gradientBorder : props.theme.buttonsBG};
  }
`;

export const StyledFrameworkCardInner = styled.div<FrameworkCardProps>`
  padding: 38px;
  background: ${(props) =>
    props.$isCurrent || props.$isOwn ? props.theme.cardsBgColor : props.theme.cardsColor};
  display: block;
  border-radius: 5px;

  &:hover {
    background: ${(props) => props.theme.cardsBgColor};
  }
`;

export const StyledFrameworkRow = styled(StyledRow)`
  justify-content: start;
  padding-top: 8px;
  padding-bottom: 44px;
  gap: 32px;
`;

export const StyledFrameworkStateText = styled.div<FrameworkCardProps>`
  background: ${(props) => (props.$isCurrent ? props.theme.textColor : props.theme.buttonHoverBG)};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  font-size: 16px;
  line-height: 24px;
  padding-top: 16px;
  text-align: center;
  font-weight: 400;
`;

export const StyledFrameworkCardItem = styled(StyledCol)`
  width: auto;
`;

export const StyledNewProjectFormError = styled(FormError)`
  margin: 10px 0px;
`;
