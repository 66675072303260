import { IconButtonProps } from '@/shared/props';
import { v4 as uuidv4 } from 'uuid';
import { Icon } from '../Icon';
import { StyledIconButton } from './U1Button.styles';

export const IconButton: React.FC<IconButtonProps> = ({
  $icon,
  onClick,
  text,
  $bgColor,
  $textColor,
  $width,
  $height,
  $padding,
  $alt = '',
  triggerRef: a11yRef,
  $ariaLabel,
}: IconButtonProps) => {
  const dialogTrigger = a11yRef ? { 'data-a11y-dialog': 'trigger', 'data-a11y-ref': a11yRef } : {};
  return (
    <StyledIconButton
      onClick={onClick}
      key={uuidv4()}
      icon={<Icon $src={$icon} $alt={$alt} />}
      $bgColor={$bgColor}
      $textColor={$textColor}
      $width={$width}
      $height={$height}
      $padding={$padding}
      aria-label={$ariaLabel}
      {...dialogTrigger}
    >
      {text}
    </StyledIconButton>
  );
};
