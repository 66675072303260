import { StyledCard, StyledMain } from '@/shared/styles';
import styled from 'styled-components';

export const StyledMainLoggedIn = styled(StyledMain)`
  min-height: 90vh;
  padding: ${(props) => (props.theme.isMobile ? `40px 16px` : `60px`)};
`;

export const StyledVendorCard = styled(StyledCard)`
  width: ${(props) => props.$width ?? '100%'};
  border: 1px solid ${(props) => props.theme.detailsBorder};
  padding: 24px;
  align-items: start;
  gap: 24px;
`;

export const Styled2fcDetails = styled.div`
  color: ${(props) => props.theme.textColor};
  border: 1px solid ${(props) => props.theme.secondBorder};
  font-weight: 100;
  border-radius: 5px;
  padding: 12px;
  height: 50px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;
