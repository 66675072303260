import { Modifications_Code } from '@/shared/consts';
import { ProductData, Quantity, SubscriptionData } from '@/shared/dto';
import { getActiveFrameworks, getModification } from '@/shared/utils';
import { SubscriptionDetails, SUB_STATUS } from '@/stores/Stores.consts';

export const extractSubscriptionDetails = (
  subscriptionData: SubscriptionData,
  prodData: ProductData
): SubscriptionDetails => {
  const domains = getModification(subscriptionData, Modifications_Code.DOMAINS);
  const frameworks = getModification(subscriptionData, Modifications_Code.FRAMEWORKS);
  const users = getModification(subscriptionData, Modifications_Code.USERS);
  const training = getModification(subscriptionData, Modifications_Code.TRAINING);
  const prodFrameworks = getModification(prodData, Modifications_Code.FRAMEWORKS);

  const subscriptionDetails: SubscriptionDetails = {
    ...subscriptionData,
    id: subscriptionData.productId,
    name: subscriptionData.productName,
    price: subscriptionData.productPrice,
    domains: (domains?.quantity as Quantity).total,
    frameworks: frameworks?.options as string[],
    additionalServices: !!(training && (training.quantity as Quantity).total),
    users: (users?.quantity as Quantity).total,
    pricingMethod: subscriptionData.paymentInterval,
    nextPaymentAmount: subscriptionData.nextPaymentAmount,
    expiryDate: new Date(subscriptionData.expiredAt),
    autoPayment: subscriptionData.autoPayment,
    isActive: subscriptionData.isActive,
    isTrial: subscriptionData.isTrial,
    paymentMethodId: subscriptionData.paymentMethodId,
    isFreemium: subscriptionData.isFreemium,
    imageUrl: prodData.imageUrl,
    supportedFrameworks: getActiveFrameworks(prodFrameworks),
    subscriptionStatus: SUB_STATUS.NOT_INITIATED,
    freemiumComponents: prodData.freemiumComponents,
  };

  return subscriptionDetails;
};

export const injectScriptTag = (script: string) => {
  const head = document.querySelector('head');
  const scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';
  scriptTag.innerHTML = script;
  head?.prepend(scriptTag);
};
