import { SIGN_IN_VIEW } from '@/Views/BeforeLogin/SignIn/SignIn.consts';
import { USER_TYPE } from '@/shared/consts';
import {
  androidAppIdExp,
  domainRegex,
  eightCharsExp,
  oneLowerCaseExp,
  oneNumberExp,
  oneUpperCaseExp,
} from '@/shared/regex';
import { AdminDetails, VendorDetails } from '@/stores/Stores.consts';
import { t } from 'i18next';
import { ReactElement } from 'react';

export type FormStyleProps = {
  $width?: string;
  $margin?: string;
  $marginTop?: string;
  $align?: string;
};

export type FromErrorProps = FormStyleProps & {
  message: string;
};

export type PasswordError = {
  text: string;
  regex: RegExp;
};

export type FormProps = {
  $darkMode?: boolean;
  $marginTop?: string;
  onOk?: () => void;
  onCancel?: () => void;
};

export const getCancellationReasons = (): Record<number, string> => ({
  0: t('billing.package_details.cancel_subscription.modal.cancellation_reason1'),
  1: t('billing.package_details.cancel_subscription.modal.cancellation_reason2'),
  2: t('billing.package_details.cancel_subscription.modal.cancellation_reason3'),
  3: t('billing.package_details.cancel_subscription.modal.cancellation_reason4'),
  4: t('billing.package_details.cancel_subscription.modal.cancellation_reason5'),
});

export type DomainProperties = {
  label: string;
  helperText: string;
  requiredMessage: string;
  patternMessage: string;
  placeholder: string;
  pattern: RegExp;
};

export const settingsDict = (): Record<string, DomainProperties> => ({
  domain: {
    label: t('projects.new_project.form.domain.text'),
    helperText: t('projects.new_project.form.domain.helper'),
    requiredMessage: t('projects.new_project.form.domain.required'),
    patternMessage: t('projects.new_project.form.domain.error'),
    placeholder: t('projects.new_project.form.domain.placeholder'),
    pattern: domainRegex,
  },
  android: {
    label: t('projects.new_project.form.app.text'),
    helperText: t('projects.new_project.form.app.helper'),
    requiredMessage: t('projects.new_project.form.app.required'),
    patternMessage: t('projects.new_project.form.app.error'),
    placeholder: t('projects.new_project.form.app.placeholder'),
    pattern: androidAppIdExp,
  },
});

export const SETTINGS_KEYS: Record<string, string> = {
  DOMAIN_KEY: 'domain',
  ANDROID_KEY: 'android',
};

export type InnerFormProps = {
  onCancel: (e: Event) => void;
  submitBtnText?: string;
};

export type EditFormProps = {
  selectedUser: AdminDetails | VendorDetails;
  onCancel?: () => void;
};

export type SignInFormProps = {
  userType?: USER_TYPE;
  setView: React.Dispatch<React.SetStateAction<SIGN_IN_VIEW>>;
};

export type PasswordValidationProps = {
  isValid: boolean;
  error: string;
};

export const getPwdErrors = (): PasswordError[] => [
  { text: `${t('create_password.requirement1')}`, regex: eightCharsExp },
  {
    text: `${t('create_password.requirement2')}`,
    regex: oneLowerCaseExp,
  },
  { text: `${t('create_password.requirement3')}`, regex: oneUpperCaseExp },
  { text: `${t('create_password.requirement4')}`, regex: oneNumberExp },
];

export type ResendTimerProps = {
  resendButton: ReactElement;
};
