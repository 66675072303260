import { FRAMEWORKS_ENUM } from '@/shared/consts';
import { ProjectListItemModel } from '@/Views/AfterLogin/Main/Client/Projects/projects.consts';
import { androidAppIdExp, comaRegex, domainRegex } from '@/shared/regex';
import { t } from 'i18next';

export const getNewProjectFormError = (project: ProjectListItemModel) => {
  const projectDomain =
    project.projectFramework === FRAMEWORKS_ENUM.ANDROID_XML_SDK
      ? t('projects.new_project.form.app_id')
      : t('projects.new_project.form.domain');
  let err = '';
  if (!project.projectDomain) {
    err += `${t('projects.new_project.form.insert')} ${projectDomain}, `;
  } else if (
    project.projectFramework === FRAMEWORKS_ENUM.ANDROID_XML_SDK &&
    !androidAppIdExp.test(project.projectDomain)
  ) {
    err += `${t('projects.new_project.form.app.error')}, `;
  } else if (
    project.projectFramework !== FRAMEWORKS_ENUM.ANDROID_XML_SDK &&
    !domainRegex.test(project.projectDomain)
  ) {
    err += `${t('projects.new_project.form.domain.error')}, `;
  }
  if (!project.projectName) {
    err += `${t('projects.new_project.form.project.error')}, `;
  }
  if (!project.projectFramework) {
    err += `${t('projects.new_project.form.framework.error')}, `;
  }

  return err.replace(comaRegex, '.');
};

export const generateEnumFramework = (framework: string) => {
  return FrameworkNameDictionary[framework];
};

export const FrameworkNameDictionary: Record<string, string> = {
  ANGULAR: FRAMEWORKS_ENUM.ANGULAR,
  JS: FRAMEWORKS_ENUM.JAVASCRIPT,
  REACT: FRAMEWORKS_ENUM.REACT,
  VUEJS: FRAMEWORKS_ENUM.VUEJS,
  ANDROID_XML_SDK: FRAMEWORKS_ENUM.ANDROID_XML_SDK,
};

export const extractDomainValidationErrors = (errorsList: Record<string, string>[]) => {
  return errorsList.map((el: any) => el.messages.join(' ')).join('\n');
};

export const getTextWithoutComments = (text: string) => {
  const commentRegex = /\/\/.*?\n/g;
  return text.replace(commentRegex, '');
};
