import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { LanguageDetectorModule } from 'i18next';
import { useLangStore } from '@/stores/LangStore/LangStore';

const LanguageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  detect: () => {
    const langPath = location.pathname.split('/')[1];
    const availableLangs = useLangStore.getState().availableLangs.map((lang) => lang.code);
    return availableLangs.includes(langPath) ? langPath : 'en';
  },
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    backend: {
      loadPath: `${import.meta.env.VITE_BASE_URL}/translations/all`,
      requestOptions: () => {
        const lang = i18n.language;
        return {
          headers: {
            'Accept-Language': lang,
          },
        };
      },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
