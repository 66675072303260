import { FRAMEWORKS_ENUM } from '@/shared/consts';
import { StyledCol, StyledInput } from '@/shared/styles';
import { useNewProjectStore } from '@/stores/ProjectStore/ProjectStore';
import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { DomainProperties, settingsDict, SETTINGS_KEYS } from '../Forms.consts';
import { StyledHelperText, StyledLabel, StyledNewProjectForm } from '../Forms.styles';
import { useTranslation } from 'react-i18next';

export const NewProjectForm: React.FC = () => {
  const [form] = Form.useForm();
  const newProjectStore = useNewProjectStore();
  const framework = newProjectStore.projectFramework;
  const [domainOrAppObject, setDomainOrAppObject] = useState<DomainProperties>({
    label: '',
    helperText: '',
    requiredMessage: '',
    patternMessage: '',
    placeholder: '',
    pattern: new RegExp(''),
  });
  const { t } = useTranslation();

  useEffect(() => {
    setDomainOrAppObject(
      framework === FRAMEWORKS_ENUM.ANDROID_XML_SDK
        ? settingsDict()[SETTINGS_KEYS.ANDROID_KEY]
        : settingsDict()[SETTINGS_KEYS.DOMAIN_KEY]
    );
  }, [framework]);

  const onValuesChange = () => {
    newProjectStore.setProject &&
      newProjectStore.setProject({
        ...newProjectStore,
        projectDomain: form.getFieldValue('projectDomain'),
        projectName: form.getFieldValue('projectName'),
      });
  };

  return (
    <StyledNewProjectForm form={form} onValuesChange={onValuesChange}>
      <StyledLabel htmlFor="projectName">{t('projects.new_project.form.name.text')}</StyledLabel>
      <Form.Item
        id="projectName"
        name="projectName"
        rules={[
          { required: true, message: t('projects.new_project.form.name.required'), max: 150 },
        ]}
      >
        <StyledInput
          data-testid="projectNameField"
          placeholder={t('projects.new_project.form.name.placeholder')}
        />
      </Form.Item>
      <StyledCol $gap={8}>
        <StyledLabel htmlFor={'projectDomain'}>{domainOrAppObject.label}</StyledLabel>
        <StyledHelperText>{domainOrAppObject.helperText}</StyledHelperText>
      </StyledCol>
      <Form.Item
        id="projectDomain"
        name="projectDomain"
        rules={[
          { required: true, message: domainOrAppObject.requiredMessage },
          { pattern: domainOrAppObject.pattern, message: domainOrAppObject.patternMessage },
        ]}
      >
        <StyledInput data-testid="projectDomainField" placeholder={domainOrAppObject.placeholder} />
      </Form.Item>
    </StyledNewProjectForm>
  );
};
