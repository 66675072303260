import { TitleProps } from '@/shared/props';
import {
  StyledCol,
  StyledRow,
  StyledRowOffset,
  StyledSecondaryText,
  StyledTitle,
} from '@/shared/styles';
import styled from 'styled-components';
import { PaymentSummaryProps } from '../Checkout.consts';

export const StyledOrderSummary = styled(StyledCol)`
  padding: ${(props) => (props.theme.isMobile ? '24' : '40')}px;
`;

export const StyledMarginTitle = styled(StyledTitle)<TitleProps>`
  margin-bottom: 35px !important;
`;

export const StyledSummary = styled(StyledCol)<PaymentSummaryProps>`
  justify-content: space-evenly;
  ${(props) => !props.isFree && !props.theme.isMobile && `height: 400px;`}
  gap: 24px;
`;

export const StyledSmallPrint = styled(StyledSecondaryText)`
  text-align: center;
  font-weight: 300;
  font-size: 13px;
  padding: 0px ${(props) => (props.theme.isMobile ? `40` : `50`)}px;
`;

export const StyledFrameworks = styled(StyledSecondaryText)`
  font-size: 16px;
  font-weight: 300;
  align-self: start;
`;

export const StyledDiscount = styled(StyledRowOffset)`
  color: ${(props) => props.theme.textColor};
  font-weight: 300;
  margin-top: 30px;
`;

export const StyledDiscountInput = styled(StyledRowOffset)`
  ${(props) =>
    props.theme.isMobile &&
    `flex-wrap: wrap;
     gap: 12px;
     input, button{
       width: 100%
     }
  `}
`;

export const StyledCodeError = styled(StyledRowOffset)`
  color: ${(props) => props.theme.errorColor};
  font-weight: 300;
  margin-top: 10px;
`;

export const StyledTerms = styled(StyledRow)`
  margin-top: 8px;
`;

export const StyledLeftAligned = styled.span`
  text-align: left;
`;

export const StyledAgreeText = styled.div`
  font-size: 16px;
`;
