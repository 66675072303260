import { create } from 'zustand';
import {
  DocsStore,
  NewProjectStore,
  BasicProjectsDataStore,
  ProjectsArrStore,
  DetailedProjectsDataStore,
  FrameworkConfigDataStore,
  StoreState,
  FrameworkStore,
} from '../Stores.consts';
import { devtools } from 'zustand/middleware';
import { FRAMEWORKS_ENUM } from '@/shared/consts';
import { extractTreeItems, getCurrentJson, removeFromJson, updateJson } from './ProjectStore.utils';
import { _axios } from '@/services/apiClient';

export const useNewProjectStore = create<NewProjectStore>()(
  devtools((set) => ({
    projectDomain: '',
    projectFramework: '',
    projectName: '',
    setProject: ({ projectDomain, projectFramework, projectName }: NewProjectStore) =>
      set(() => ({ projectDomain, projectFramework, projectName })),
    setProjectName: (projectName: string) => set(() => ({ projectName })),
    setProjectDomain: (projectDomain: string) => set(() => ({ projectDomain })),
    setProjectFramework: (projectFramework: string) => set(() => ({ projectFramework })),
  }))
);

export const useBasicProjectsDataStore = create<BasicProjectsDataStore>()(
  devtools((set) => ({
    id: '',
    name: '',
    domain: '',
    framework: '',
    frameworkIcon: '',
    createdAt: 0,
    updatedAt: 0,
    setProject: ({ id, name, domain, framework, createdAt, updatedAt }) =>
      set(() => ({ id, name, domain, framework, createdAt, updatedAt })),
  }))
);

export const useProjectsArrStore = create<ProjectsArrStore>()(
  devtools((set) => ({
    projectsArr: [],
    projectsState: StoreState.NOT_INITIATED,
    setProjectsArr: (arr: BasicProjectsDataStore[]) =>
      set(() => ({ projectsArr: arr, projectsState: StoreState.INITIATED })),
    setEmptyArr: () => set(() => ({ projectsArr: [] })),
  }))
);

export const useDocsStore = create<DocsStore>()(
  devtools((set, get) => ({
    framework: '',
    selectedComponent: 0,
    docsObj: [],
    componentsTreeList: [],
    compTreeItems: [],
    filteredTreeList: [],
    projectId: '',
    compIcons: {},
    docsLang: 'jsx',
    addNewProps: (objkeys) => {
      const json = get().selectedComponentDoc;
      const currentJson = getCurrentJson(json, json, objkeys);
      currentJson.table.push({});
    },
    setDocsObj: (docsObj) => {
      //comp names
      const compNames = docsObj.map((comp) => comp.name);
      get().setComponentsTreeList(compNames);

      //comp icons
      get().setCompIcons(docsObj);

      //comp docs
      const selectedComponentDoc = docsObj[0];
      const docsLang = get().framework === FRAMEWORKS_ENUM.REACT ? `jsx` : `html`;
      set((state) => ({
        ...state,
        docsObj,
        selectedComponentDoc,
        docsLang,
      }));
    },
    updateDocsObj: (objecKeys: string[], updatedPart: string | string[], index?: number) => {
      const selectedComponentDoc = updateJson(
        { ...get().selectedComponentDoc },
        objecKeys,
        updatedPart,
        index
      );
      set((state) => ({
        ...state,
        selectedComponentDoc,
      }));
    },
    removeFromDocs: (objecKeys: string[], index: number) => {
      const selectedComponentDoc = removeFromJson(get().selectedComponentDoc, objecKeys, index);
      set((state) => ({
        ...state,
        selectedComponentDoc,
      }));
    },
    setFramework: (framework) =>
      set((state) => ({
        ...state,
        framework,
      })),
    setSelectedComponent: (selectedComponent: number) => {
      const selectedComponentDoc = get().docsObj[selectedComponent];
      set((state) => ({
        ...state,
        selectedComponent,
        selectedComponentDoc,
      }));
    },
    setComponentsTreeList: (componentsTreeList) => {
      set((state) => ({
        ...state,
        componentsTreeList,
        compTreeItems: extractTreeItems(componentsTreeList, componentsTreeList),
      }));
    },
    setFilteredTreeList: (componentsTreeList) => {
      const compTreeItems = extractTreeItems(get().componentsTreeList, componentsTreeList);
      set((state) => ({
        ...state,
        filteredTreeList: componentsTreeList,
        compTreeItems,
      }));
    },
    setProjectId: (projectId) =>
      set((state) => ({
        ...state,
        projectId,
      })),
    setCompIcons: (docsObj) => {
      const compIcons: Record<string, Record<string, string>> = {};
      docsObj?.forEach((comp) => {
        compIcons[comp.name] = {
          active: comp.icon.selected,
          inactive: comp.icon.default,
        };
      });
      set((state) => ({
        ...state,
        compIcons,
      }));
    },
  }))
);

export const useProjectsDataStore = create<DetailedProjectsDataStore>()(
  devtools((set) => ({
    id: '',
    name: '',
    domain: '',
    framework: '',
    frameworkIcon: '',
    createdAt: 0,
    updatedAt: 0,
    registryUrl: '',
    token: {
      value: 'authorizationTokenVar',
      permissions: ['READ'],
      lastValidatedAt: 0,
    },
    credentials: [
      {
        type: '',
        accessType: '',
        token: {
          value: '',
          permissions: ['READ'],
          lastValidatedAt: 0,
        },
        basic: {
          username: '',
          password: '',
          permissions: [],
        },
      },
    ],
    setDetailedProject: ({
      id,
      name,
      domain,
      framework,
      createdAt,
      updatedAt,
      registryUrl,
      token,
      credentials,
    }) =>
      set(() => ({
        id,
        name,
        domain,
        framework,
        createdAt,
        updatedAt,
        registryUrl,
        token,
        credentials,
      })),
  }))
);

export const useConfigDataStore = create<FrameworkConfigDataStore>()(
  devtools((set, get) => ({
    framework: '',
    type: '',
    content: [],
    updateConfigObj: (objecKeys: string[], updatedPart: string | string[], index?: number) => {
      const content = updateJson({ ...get().content }, objecKeys, updatedPart, index);
      set((state) => ({
        ...state,
        content: Object.values(content),
      }));
    },
    setFrameworkConfigData: ({ framework, type, content }) =>
      set(() => ({
        framework,
        type,
        content,
      })),
  }))
);

export const useFrameworkStore = create<FrameworkStore>()((set) => ({
  total: 0,
  data: [],
  getFrameworksData: () => {
    set((state) => ({ ...state, isLoading: true }));
    _axios
      .get('/frameworks?limit=50')
      .then((res) => {
        const { data, total } = res.data;
        set(() => ({ data, total, isLoading: false }));
      })
      .catch(() => {
        set((state) => ({ ...state, isLoading: false }));
      });
  },
}));
