import { create } from 'zustand';
import { LangDto, LangStore, supportedLanguages } from './LangStore.consts';
import { _axios } from '@/services/apiClient';

export const useLangStore = create<LangStore>((set, get) => ({
  availableLangs: supportedLanguages,
  getLanguage: (value: string) => {
    return get().availableLangs.find((lang) => lang.value === Number(value))?.code;
  },
  getAllLanguages: () => {
    _axios.get('translations/languages').then((data) => {
      const languages = data.data.filter((lang: LangDto) => lang.active);
      const availableLangs = languages.map((lang: LangDto, i: number) => ({
        value: i + 1,
        label: lang.name,
        code: lang.code,
        direction: lang.direction,
      }));
      set((state) => ({ ...state, availableLangs }));
    });
  },
  isLangSupported: () => {
    const allLangs = get().availableLangs.map((lang) => lang.code);
    const pathName = location.pathname;
    const langPath = pathName.split('/')[1];
    return allLangs.includes(langPath);
  },
  getSelectedLang: () => {
    return get().isLangSupported() ? location.pathname.split('/')[1] : 'en';
  },
  getLangDir: () => {
    const selectedLang = get().getSelectedLang();
    return get().availableLangs.find((lang) => lang.code === selectedLang)?.direction;
  },
  isRtl: () => {
    return get().getLangDir() === 'rtl';
  },
}));
