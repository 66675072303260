import React, { useState } from 'react';
import { U1Button } from '@/shared/components/Button/U1Button';
import Title from 'antd/lib/typography/Title';
import { ContactUsForm } from '@/shared/components/Forms/ContactUs/ContactUsForm';
import { StyledModal } from '../Modals.styles';
import { StyledSecondaryText } from '@/shared/styles';
import { U1Dialog } from '@u1/react-a11y-hooks';
import { U1Alert } from '../../U1Alert';
import { ComponentName } from '@u1/ui-library-mapper';
import { useTranslation } from 'react-i18next';

export const ContactUsModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const { t } = useTranslation();

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setModalMsg('Sent successfully');
    setEmailSent(true);
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  return (
    <U1Dialog name={ComponentName.CONTACT_US_DIALOG}>
      <U1Button
        key="contact-us"
        triggerRef="contact-us"
        dataTestId="contactUsButton"
        text={t('pricing.package.contact_us')}
        $height={'40px'}
        onClick={showModal}
      />
      <StyledModal
        data-a11y-ref="contact-us"
        open={open}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        footer={[]}
      >
        {!emailSent && (
          <>
            <Title>{t('pricing.contact_us.title')}</Title>
            <StyledSecondaryText fontSize="15" fontWeight={100}>
              {t('pricing.contact_us.content')}
            </StyledSecondaryText>
          </>
        )}
        {modalMsg && <U1Alert>{modalMsg}</U1Alert>}
        {!emailSent && <ContactUsForm onOk={handleOk} />}
      </StyledModal>
    </U1Dialog>
  );
};
