import styled from 'styled-components';
import { StyledCol, StyledRow, StyledText, StyledTitle } from '@/shared/styles';
import { StyledRightSection } from '../../SignIn/SSO/Signin.styles';
import { isMobile } from 'react-device-detect';
import {
  StyledPinkGradientLink,
  StyledPinkRouterLink,
} from '@/shared/components/Link/Links.styles';

export const StyledMainPageFooter = styled.div`
  background-color: ${(props) => props.theme.marginsColor};
  height: 30%;
  width: 100%;
`;

export const StyledWhiteText = styled(StyledText)`
  font-size: ${(props) => (props.theme.isMobile ? '20' : '32')}px !important;
  line-height: 48px;
  ${(props) => props.theme.isMobile && `font-weight: 600;  `}
`;

export const StyledFooterTopRow = styled(StyledRow)`
  width: 100%;
  padding: ${(props) => (props.theme.isMobile ? '24px 16px 24px 16px' : '60px 252px 60px 252px')};
  min-height: ${isMobile ? '182' : '296'}px;
`;

export const StyledCenteredCol = styled(StyledCol)`
  justify-content: center;
  align-self: center;
  align-items: center;
`;

export const StyledFooterLeftSection = styled(StyledCol)`
  align-items: ${isMobile ? 'center' : 'flex-start'};
  & a {
    margin-top: 20px;
    font-size: 20px !important;
  }
`;

export const StyledFreeTrial = styled(StyledFooterLeftSection)`
  gap: ${isMobile ? '24' : '30'}px;
`;

export const StyledFreeText = styled(StyledFooterLeftSection)`
  text-align: ${isMobile ? 'center' : 'left'};
`;

export const StyledFooterRightSection = styled(StyledRightSection)`
  background: unset;
`;

export const StyledFooterTitle = styled(StyledTitle)`
  padding: 10px 20px;
  font-size: 16px !important;
  font-weight: 400 !important;
`;

export const StyledFooterLink = styled(StyledPinkRouterLink)`
  font-weight: 400 !important;
  ${!isMobile && `padding:  10px 20px;`}
`;

export const StyledFooterEmail = styled(StyledPinkGradientLink)`
  font-weight: 400 !important;
  ${!isMobile && `padding:  10px 20px;`}
`;

export const StyledMainFooter = styled(StyledCol)`
  padding: ${isMobile ? '40px' : '40px 100px'};
  gap: 32px;
`;

export const StyledSocialLinks = styled(StyledRow)`
  gap: 24px;
  height: fit-content;
`;

const StyledFlex = styled.div`
  display: flex;
  flex-direction: ${isMobile ? 'column' : 'row'};
`;

export const StyleMainFooterLinks = styled(StyledFlex)`
  gap: 72px;
`;

export const StyleMainFooterItems = styled(StyledFlex)`
  justify-content: space-between;
  ${isMobile &&
  `gap: 50px;
   align-items: center;`}
`;

export const StyledFooterItem = styled(StyledCol)`
  gap: 12px;
  width: ${isMobile ? '100%' : 'fit-content'};
  ${isMobile && `align-items: center;`}
`;

export const StyledPolicies = styled(StyledFlex)`
  gap: 64px;
`;

export const StyledBottomFooter = styled(StyledFlex)`
  justify-content: ${isMobile ? 'space-around' : 'space-between'};
  gap: 64px;
`;
