import { Route, Routes } from 'react-router-dom';
import { StyledMainLoggedIn } from '../Main.styles';
import { PAGES } from '@/shared/consts';
import { AdminsSection } from './AdminsPanel/Admins';
import { VendorsSection } from './VendorsPanel/Vendors';
import { AdminDocs } from './AdminDocs/AdminDocs';
import { DocumentationPage } from '../Docs/DocumentationPage/DocumentationPage';
import { ConfigurationPage } from '../Docs/ConfigurationPage/ConfigurationPage';
import { supportedLanguages } from '@/stores/LangStore/LangStore.consts';

export const AdminView: React.FC = () => {
  return (
    <StyledMainLoggedIn>
      <Routes>
        {/* lang routes */}
        {supportedLanguages.map((lang) => (
          <Route key={lang.code} path={`/${lang.code}`}>
            <Route path={PAGES.SHARED.DEFAULT.replace('/', '')} element={<AdminDocs />} />
            <Route path={PAGES.AFTER_LOGIN.ADMIN_DOCS.replace('/', '')} element={<AdminDocs />} />
            <Route path={PAGES.AFTER_LOGIN.ADMINS.replace('/', '')} element={<AdminsSection />} />
            <Route path={PAGES.AFTER_LOGIN.VENDORS.replace('/', '')} element={<VendorsSection />} />
            <Route
              path={PAGES.AFTER_LOGIN.ADMIN_DOCUMENTATION.replace('/', '')}
              element={<DocumentationPage />}
            />
            <Route
              path={PAGES.AFTER_LOGIN.ADMIN_CONFIGURATION.replace('/', '')}
              element={<ConfigurationPage />}
            />
          </Route>
        ))}
        {/* default routes */}
        <Route path={PAGES.SHARED.DEFAULT} element={<AdminDocs />} />
        <Route path={PAGES.AFTER_LOGIN.ADMIN_DOCS} element={<AdminDocs />} />
        <Route path={PAGES.AFTER_LOGIN.ADMINS} element={<AdminsSection />} />
        <Route path={PAGES.AFTER_LOGIN.VENDORS} element={<VendorsSection />} />
        <Route path={PAGES.AFTER_LOGIN.ADMIN_DOCUMENTATION} element={<DocumentationPage />} />
        <Route path={PAGES.AFTER_LOGIN.ADMIN_CONFIGURATION} element={<ConfigurationPage />} />
      </Routes>
    </StyledMainLoggedIn>
  );
};
