import { ArrowDownIcon } from '@/assets/images/icons';
import { Icon } from '@/shared/components/Icon';
import { useUserStore } from '@/stores/UserStore';
import { isBrowser } from 'react-device-detect';
import {
  StyledDetails,
  StyledEmail,
  StyledUserDetails,
  StyledUserName,
} from './UserDropdown.styles';

export const UserDetails: React.FC = () => {
  const userDetails = useUserStore();

  return (
    <StyledUserDetails>
      <Icon $src={userDetails.avatarUrl} $borderRadius={50} $size={45} />
      <StyledDetails>
        <StyledUserName data-testid="userName">
          {userDetails.fullName ?? userDetails.firstName}
        </StyledUserName>
        <StyledEmail aria-hidden="true" data-testid="userEmail">
          {userDetails.email}
        </StyledEmail>
      </StyledDetails>
      {isBrowser && <Icon $src={ArrowDownIcon} />}
    </StyledUserDetails>
  );
};
