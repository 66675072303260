import { ButtonProps } from '@/shared/props';
import { U1Link } from '@u1/react-a11y-hooks';
import { v4 as uuidv4 } from 'uuid';
import { StyledButton } from './U1Button.styles';

export const U1Button: React.FC<ButtonProps> = ({
  text,
  id,
  $width,
  $height,
  onClick,
  $bgColor,
  $textColor,
  htmlType = 'button',
  $padding,
  disabled,
  dataTestId,
  $ariaLabel,
  className,
  triggerRef: a11yRef,
  isLink = false,
}: ButtonProps) => {
  const dialogTrigger = a11yRef ? { 'data-a11y-dialog': 'trigger', 'data-a11y-ref': a11yRef } : {};

  const props = {
    id,
    key: uuidv4(),
    $bgColor,
    $textColor,
    $width,
    $height,
    onClick,
    htmlType,
    $padding,
    disabled,
    className: `${className} ant-custom-${isLink ? `link` : `btn`}`,
    'aria-label': $ariaLabel,
    'data-testid': dataTestId,
    ...dialogTrigger,
  };

  return isLink ? (
    <U1Link>
      <StyledButton data-u1-link="link" {...props}>
        {text}
      </StyledButton>
    </U1Link>
  ) : (
    <StyledButton {...props}>{text}</StyledButton>
  );
};
