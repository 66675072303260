import { useAxios } from '@/services/apiClient';
import {
  useProjectsDataStore,
  useProjectsArrStore,
  useDocsStore,
  useConfigDataStore,
} from '@/stores/ProjectStore/ProjectStore';
import { useEffect } from 'react';

export const useProjects = () => {
  const detailedProjectsDataStore = useProjectsDataStore();
  const projectsArrStore = useProjectsArrStore();
  const docsStore = useDocsStore();
  const configStore = useConfigDataStore();

  const [{ data: adminDocsData, loading: adminDocsLoading }, getDocsById] = useAxios(
    { method: 'get', url: `/frameworks` },
    { manual: true }
  );

  const [{ data: projectsData }, getProjects] = useAxios(
    { method: 'get', url: '/projects' },
    { manual: true }
  );

  const [{ data: detailedProjectData, loading: projectLoading }, getDetailedProject] = useAxios(
    { method: 'get', url: `/projects` },
    { manual: true }
  );

  useEffect(() => {
    if (adminDocsData) {
      docsStore.setDocsObj(adminDocsData.docs);
      configStore.setFrameworkConfigData({
        content: adminDocsData.config,
        framework: '',
        type: '',
      });
    }
  }, [adminDocsData]);

  useEffect(() => {
    if (projectsData) {
      projectsArrStore.setProjectsArr(projectsData);
    }
  }, [projectsData]);

  useEffect(() => {
    if (detailedProjectData) {
      detailedProjectsDataStore.setDetailedProject(detailedProjectData);
    }
  }, [detailedProjectData]);

  const [{ data: projectsDocsData, loading: projectsDocsLoading }, getProjectsDocs] = useAxios(
    { method: 'get', url: `/projects/${docsStore.projectId}/docs` },
    { manual: true }
  );

  useEffect(() => {
    if (projectsDocsData) {
      docsStore.setDocsObj(projectsDocsData.content);
    }
  }, [projectsDocsData]);

  const [
    { data: frameworkConfigData, loading: frameworkConfigDataLoading },
    getFrameworkConfigData,
  ] = useAxios({ method: 'get' }, { manual: true });

  useEffect(() => {
    if (frameworkConfigData) {
      configStore.setFrameworkConfigData(frameworkConfigData);
    }
  }, [frameworkConfigData]);

  return {
    getProjects,
    getDetailedProject,
    getProjectsDocs,
    getFrameworkConfigData,
    getDocsById,
    adminDocsLoading,
    projectLoading,
    projectsDocsData,
    projectsDocsLoading,
    projectsData,
    frameworkConfigData,
    frameworkConfigDataLoading,
  };
};
