import { DealClientDto, DealsData, PaymentDetails } from '@/shared/dto';
import { SUBSCRIPTION_TYPE } from '@/Views/AfterLogin/Main/Vendor/Panels/Deals/Deals.consts';
import {
  getAdditionalHoursAmount,
  getDomainsAmount,
  getUserFrameworks,
  getUsersAmount,
} from '@/Views/AfterLogin/Main/Vendor/Panels/Deals/Deals.utils';
import { DealSteps, DealStore } from './DealsStore.consts';
import { t } from 'i18next';

export const extractDeal = (deal: DealsData) => {
  return {
    id: deal.id,
    idNumber: deal.idNumber,
    dealStep: DealSteps[deal.step],
    subscriptionType: Object.values(SUBSCRIPTION_TYPE).indexOf(deal.paymentInterval),
    price: String(deal.product.price),
    users: getUsersAmount(deal.product.modifications),
    domains: getDomainsAmount(deal.product.modifications),
    additonalServices: getAdditionalHoursAmount(deal.product.modifications),
    frameworks: getUserFrameworks(deal.product.modifications),
  };
};

export const extractClient = (client: DealClientDto) => {
  return {
    client: {
      company: client.companyName,
      fullname: client.fullName,
      email: client.email,
      phone: client.phone,
      country: client.country,
    },
  };
};

export const extractPaymentDetails = (payment: PaymentDetails) => {
  return {
    paymentDetails: {
      cardLast4: payment.cardLast4,
      paidAmount: payment.paidAmount,
      paidDate: payment.paidDate,
      paymentMethodBrand: payment.paymentMethodBrand,
    },
  };
};

export const removedDealDetails = () => {
  return {
    id: undefined,
    subscriptionType: SUBSCRIPTION_TYPE.TWO_YEARS,
    users: '',
    frameworks: [],
    domains: '',
    additonalServices: 0,
    price: '',
    client: undefined,
  };
};

export const getStep1FormErrors = (get: () => DealStore): string => {
  if (!get().users) return t('vendor.deals.create_deal.error.users_amount');
  if (Number(get().users) <= 0) return t('vendor.deals.create_deal.error.one_user');
  if (get().frameworks.length <= 0) return t('vendor.deals.create_deal.error.one_framework');
  if (Number(get().domains) <= 0) return t('vendor.deals.create_deal.error.one_domainn');
  if (!get().price || Number(get().price) < 2000)
    return t('vendor.deals.create_deal.error.minimum');
  return '';
};
