import { MainMenu } from './Menu/Menu';
import { StyledLeftSection, StyledRightSection } from './Header.styles';
import { U1Button } from '@/shared/components/Button/U1Button';
import { U1Logo } from '@/shared/components/U1Logo';
import { StyledHeader, StyledRow } from '@/shared/styles';
import { PAGES, USER_TYPE } from '@/shared/consts';
import { isMobile } from 'react-device-detect';
import { MobileHeader } from '@/shared/mobile/Header/MobileHeader';
import { useUserStore } from '@/stores/UserStore';
import { LangSwitch } from './Lang/LangSwitch';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const Header: React.FC = () => {
  const { navigate } = useU1Navigation();
  const userType = useUserStore((state) => state.type);
  const { t } = useTranslation();

  return isMobile ? (
    <MobileHeader />
  ) : (
    <StyledHeader $isClient={userType === USER_TYPE.CLIENT}>
      <StyledLeftSection>
        <U1Logo $size={40} />
        <MainMenu />
      </StyledLeftSection>
      <StyledRightSection>
        <StyledRow>
          <U1Button
            dataTestId="signInButton"
            text={t('header.sign_in')}
            $bgColor={'none'}
            onClick={() => navigate(PAGES.BEFORE_LOGIN.SIGN_IN)}
            isLink={true}
          />
          <U1Button
            dataTestId="tryNowButton"
            text={t('header.try_now')}
            onClick={() => navigate(PAGES.BEFORE_LOGIN.PRICING)}
            isLink={true}
          />
        </StyledRow>
        <LangSwitch />
      </StyledRightSection>
    </StyledHeader>
  );
};
