import { ContactUsModal } from '@/shared/components/Modals/ContactUsModal/ContactUsModal';
import { StyledColOffset } from '@/shared/styles';
import { PackgeDetailsProps } from '../../Pricing.consts';
import { StyledDetailsContainer } from '../Package.styles';
import { PackageDetails } from '../PackageDetails/PackageDetails';

export const CustomPackageDetails: React.FC<PackgeDetailsProps> = ({
  users,
  frameworks,
  domains,
}: PackgeDetailsProps) => {
  return (
    <StyledColOffset>
      <StyledDetailsContainer>
        <PackageDetails
          users={users.quantity > -1 ? users.quantityTitle : ''}
          frameworks={frameworks.quantity > -1 ? frameworks.quantityTitle : ''}
          domains={domains.quantity > -1 ? domains.quantityTitle : ''}
          training={''}
        />
        <ContactUsModal />
      </StyledDetailsContainer>
    </StyledColOffset>
  );
};
