import { lightningIcon } from '@/assets/images/icons';
import { IconButton } from '@/shared/components/Button/IconButton';
import { U1Logo } from '@/shared/components/U1Logo';
import { PAGES, USER_TYPE } from '@/shared/consts';
import { MobileHeader } from '@/shared/mobile/Header/MobileHeader';
import { StyledRow } from '@/shared/styles';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { isMobile } from 'react-device-detect';
import { useTheme } from 'styled-components';
import { StyledLoggedInHeader } from './Header.styles';
import { UserDropdown } from './UserDropdown/UserDropdown';
import { useUserStore } from '@/stores/UserStore';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';
import { LangSwitch } from '@/Views/BeforeLogin/Header/Lang/LangSwitch';

export const Header: React.FC = () => {
  const theme = useTheme();
  const { navigate } = useU1Navigation();
  const subscription = useSubscriptionStore();
  const userType = useUserStore((state) => state.type);
  const { t } = useTranslation();

  return isMobile ? (
    <MobileHeader />
  ) : (
    <StyledLoggedInHeader $isClient={userType === USER_TYPE.CLIENT}>
      <U1Logo $size={45} $autofocus={true} />
      <StyledRow $gap={35}>
        {subscription.isFreemium && (
          <IconButton
            $bgColor={theme.twoColorButtonBG}
            text={t('projects.upgrade.title')}
            $icon={lightningIcon}
            $textColor={theme.textColor}
            $height={'50px'}
            $width={'150px'}
            onClick={() => navigate(PAGES.AFTER_LOGIN.PRICING)}
          />
        )}
        <LangSwitch />
        <UserDropdown />
      </StyledRow>
    </StyledLoggedInHeader>
  );
};
