import i18n from '@/services/localization';
import { Menu } from 'antd';
import styled from 'styled-components';

export const StyledMenu = styled(Menu)`
  background: none;
  border: none;
  color: ${(props) => props.theme.menuTextColor};
  min-width: 510px;

  &:hover::after {
    border: none !important;
  }

  .ant-menu-item:hover::after,
  .ant-menu-submenu:hover::after,
  .ant-menu-item-active::after,
  .ant-menu-submenu-active::after,
  .ant-menu-item-open::after,
  .ant-menu-submenu-open::after,
  .ant-menu-item-selected::after,
  .ant-menu-submenu-selected::after,
  .ant-menu-item::after,
  .ant-menu-submenu::after {
    border: none !important;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: ${(props) => props.theme.menuTextColor} !important;
  }
`;

export const Submenu = styled(Menu.SubMenu).attrs(() => ({
  dir: i18n.dir(i18n.language),
}))`
  &:hover::after {
    border: none !important;
  }
`;
