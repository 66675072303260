export const trustedCompaneis = [
  {
    alt: 'IKEA',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/ikea.png',
  },
  {
    alt: 'Mount Sinai',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/mountSinai.png',
  },
  {
    alt: 'Office Of The Comptroller',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/comptroller.png',
  },
  {
    alt: 'Eleos',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/eleos.png',
  },
  {
    alt: 'Amtrak',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/amtrak.png',
  },
  {
    alt: 'Lending Club',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/lendingClub.png',
  },
  {
    alt: 'Eagle Bank',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/eagleBank.png',
  },
  {
    alt: 'Santander',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/santander.png',
  },
  {
    alt: 'Fannie Mae',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/fannieMae.png',
  },
  {
    alt: 'Molina Healthcare',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/molina.png',
  },
  {
    alt: 'T-Mobile',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/tmobile.png',
  },
  {
    alt: 'Lightico',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/lightico.png',
  },
  {
    alt: 'United Nations',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/UN.png',
  },
  {
    alt: 'IRobot',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/irobot.png',
  },
  {
    alt: 'Butler University',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/butler.png',
  },
  {
    alt: 'Blink Health',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/blink.png',
  },
  {
    alt: 'IDB',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/IDB.png',
  },
  {
    alt: 'American Century Investments',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/americanCentury.png',
  },
  {
    alt: 'Orage Country Library',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/orangeCounty.png',
  },
  {
    alt: 'Bank Leumi',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/leumi.png',
  },
];
