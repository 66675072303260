import { useLangStore } from '@/stores/LangStore/LangStore';
import { useNavigate } from 'react-router-dom';

export const useU1Navigation = () => {
  const navigateTo = useNavigate();
  const isLangSupported = useLangStore((state) => state.isLangSupported);
  const getSelectedLang = useLangStore((state) => state.getSelectedLang);

  const navigate = (path: string) => {
    if (isLangSupported()) {
      const lang = getSelectedLang();
      navigateTo(`/${lang}${path}`);
    } else {
      navigateTo(`${path}`);
    }
  };

  const getLangRoute = () => {
    if (isLangSupported()) {
      const lang = getSelectedLang();
      return `${lang}`;
    } else {
      return ``;
    }
  };

  return { navigate, getLangRoute };
};
