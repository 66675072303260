import { U1Button } from '@/shared/components/Button/U1Button';
import { MobileMenu } from '@/shared/components/Modals/MobileMenuModal/MobileMenu';
import { U1Logo } from '@/shared/components/U1Logo';
import { PAGES } from '@/shared/consts';
import { StyledHeader } from '@/shared/styles';
import { useAuthStore } from '@/stores/AuthStore';
import { StyledLeftSection, StyledRightSection } from './MobileHeader.styles';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const MobileHeader: React.FC = () => {
  const { navigate } = useU1Navigation();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const { t } = useTranslation();

  return (
    <StyledHeader>
      <StyledLeftSection>
        <U1Logo $size={40} />
      </StyledLeftSection>
      <StyledRightSection>
        {!isLoggedIn && (
          <U1Button
            dataTestId="tryNowButton"
            text={t('header.try_now')}
            onClick={() => navigate(PAGES.BEFORE_LOGIN.PRICING)}
          />
        )}
        <MobileMenu />
      </StyledRightSection>
    </StyledHeader>
  );
};
