import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { App } from './App';
import { GlobalStyle } from './globalStyles';
import { defaultTheme } from './shared/themes/themes';
import { CookiesProvider } from 'react-cookie';
import { U1Scrollbar } from './shared/components/Scrollbar/U1Scrollbar';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <CookiesProvider>
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <BrowserRouter>
        <U1Scrollbar>
          <App />
        </U1Scrollbar>
      </BrowserRouter>
    </ThemeProvider>
  </CookiesProvider>
);
