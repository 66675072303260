import i18n from '@/services/localization';
import styled from 'styled-components';

export const StyledArrow = styled.img.attrs(() => ({
  dir: i18n.dir(i18n.language),
}))`
  margin-left: 10px;

  &[dir='rtl'] {
    margin-right: 10px;
  }
`;
