import { IconProps } from '@/shared/props';
import React from 'react';
import { StyledIcon } from '../styles';

export const Icon: React.FC<IconProps> = ({
  $src,
  $size,
  $top,
  $right,
  $left,
  $borderRadius,
  $height,
  $position,
  $width,
  $alt = '',
  $className,
  $loading,
  dataTestId,
}: IconProps) => {
  const sizeProps = $size
    ? { width: $size, height: $size }
    : $width && $height
    ? { width: $width, height: $height }
    : {};
  return (
    <StyledIcon
      src={$src}
      $top={$top}
      $right={$right}
      $left={$left}
      $borderRadius={$borderRadius}
      $position={$position}
      alt={$alt}
      className={$className ?? ''}
      {...sizeProps}
      loading={$loading}
      title={$alt}
      data-testid={dataTestId}
    />
  );
};
