import { StyledCol } from '@/shared/styles';
import { Modal, ModalProps } from 'antd';
import styled from 'styled-components';

export const StyledMenuList = styled.ul`
  color: ${(props) => props.theme.textColor};
  list-style: none;
  padding: 0;

  ul {
    list-style: none;
  }

  a,
  span {
    color: ${(props) => props.theme.textColor};
    font-weight: 400;
    font-size: 14px;
  }

  .ant-collapse .ant-collapse-header {
    padding: 0;
  }

  > li {
    padding: 12px;
  }

  > li:not(:last-child) {
    margin-bottom: 32px;
  }

  .has-submenu li {
    margin-top: 32px;
  }

  .has-submenu {
    color: ${(props) => props.theme.secondaryTextColor};
  }
`;

export const StyledMobileMenu = styled(Modal)<ModalProps>`
  background: ${(props) => props.theme.marginsColor};
  top: 0;
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;

  .ant-modal-body {
    padding: 16px;
    padding-top: 15%;
  }
  .ant-modal-content {
    background: ${(props) => props.theme.marginsColor};
    box-shadow: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-close {
    color: ${(props) => props.theme.textColor};
    opacity: 1;
  }
`;

export const StyledTryNowMobile = styled(StyledCol)`
  color: ${(props) => props.theme.textColor};
  border: 1px solid ${(props) => props.theme.secondBorder};
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  gap: 16px;
  padding: 12px;

  span {
    padding: 5px;
  }
`;

export const StyleMobileMenu = styled(StyledCol)`
  gap: 24px;
`;
