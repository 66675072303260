import { FrameProps } from '@/shared/props';
import styled from 'styled-components';

export const StyledSider = styled.aside<FrameProps>`
  background: ${(props) =>
    props.$isClient ? props.theme.marginsColor : props.theme.secondMarginsColor};
  min-width: 82px;
  width: fit-content;
  ${(props) => !props.$isClient && `border-right: 1px solid ${props.theme.marginsBorder}`};
`;
