import { Menu } from 'antd';
import styled from 'styled-components';

export const StyledMenu = styled(Menu)`
  width: 100% !important;
  background: transparent;
  margin-top: 40px;
  color: ${(props) => props.theme.textColor};

  li {
    font-weight: 300;
    letter-spacing: 0.5px;
    display: flex;
    flex-direction: column;
    height: fit-content !important;
    padding: 10px 0px !important;
    border-left: 3px solid ${(props) => props.theme.marginsColor};
  }
`;

export const StyleMenuWrapper = styled.div`
  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu-item-active,
  .ant-menu-item-selected {
    color: ${(props) => props.theme.textColor} !important;
  }

  .ant-menu-item:active {
    background-color: ${(props) => props.theme.selectedButtonBG};
  }

  .ant-menu-inline,
  .ant-menu-inline .ant-menu-item::after {
    border: none !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: ${(props) => props.theme.selectedButtonBG} !important;
    border-style: solid;
    border-width: 0px 0px 0px 3px;
    border-image: ${(props) => props.theme.buttonsBG} 1;
  }

  .ant-menu-inline .ant-menu-item {
    margin-bottom: 15px;
    border: none;
  }

  .ant-menu-title-content {
    height: 30px;
  }
`;
