import { Modifications_Code } from '@/shared/consts';
import { CouponData, OrderCart, OrderProps, UpgradeProps } from '@/shared/dto';
import {
  SelectionType,
  ExtraSelectionType,
  SubscriptionDetails,
  PackagesStore,
} from '@/stores/Stores.consts';
import { PackageExtra } from './Checkout.consts';
import { t } from 'i18next';

export const calcExtraFrameworks = (
  userSelection: ExtraSelectionType,
  selectedPackage: SelectionType,
  packages: PackagesStore
): number => {
  const extraFrameworks = Math.max(
    userSelection?.frameworks.length - selectedPackage?.frameworks.quantity,
    0
  );
  const frameworkPrice = packages.getPricing(
    selectedPackage.name,
    selectedPackage.pricingMethod,
    PackageExtra.FRAMEWORK
  );
  const totalPrice = extraFrameworks * frameworkPrice;
  userSelection.totalPrice.extraFrameworks = totalPrice;
  return totalPrice;
};

export const calcExtraDomains = (
  userSelection: ExtraSelectionType,
  selectedPackage: SelectionType,
  packages: PackagesStore
): number => {
  const domainPrice = packages.getPricing(
    selectedPackage.name,
    selectedPackage.pricingMethod,
    PackageExtra.DOMAIN
  );
  const totalPrice = userSelection.domains * domainPrice;
  userSelection.totalPrice.extraDomains = totalPrice;
  return totalPrice;
};

export const calcExtraTraining = (
  userSelection: ExtraSelectionType,
  selectedPackage: SelectionType | SubscriptionDetails,
  packages: PackagesStore
): number => {
  const trainingPrice = packages.getPricing(
    selectedPackage.name,
    selectedPackage.pricingMethod,
    PackageExtra.TRAINING
  );
  const totalPrice = userSelection.additionalService ? trainingPrice : 0;
  userSelection.totalPrice.additionalService = totalPrice;
  return totalPrice;
};

export const calcTotalPrice = (
  userSelection: ExtraSelectionType,
  selectedPackage: SelectionType
): number => {
  const totalPrice =
    (selectedPackage.price as number) +
    userSelection.totalPrice.extraFrameworks +
    userSelection.totalPrice.extraDomains +
    userSelection.totalPrice.additionalService -
    (userSelection.discount?.value ?? 0);
  userSelection.totalPrice.total = totalPrice;
  return totalPrice;
};

export const calcExtras = (userSelection: ExtraSelectionType): number => {
  return (
    userSelection.totalPrice.extraFrameworks +
    userSelection.totalPrice.extraDomains +
    userSelection.totalPrice.additionalService
  );
};

//If user chooses extra frameworks, he will get extra domains
export const getExtraDomainsPerFW = (
  userSelection: ExtraSelectionType,
  selectedPackage: SelectionType
): number => {
  const numOfExtraFrameworks = Math.max(
    userSelection.frameworks.length - selectedPackage.frameworks.quantity,
    0
  );
  return numOfExtraFrameworks * selectedPackage.domains.quantity;
};

export const calcPackageDomains = (selectedPackage: SelectionType): number => {
  return selectedPackage.domains.quantity + (selectedPackage.extraDomains ?? 0);
};

export const getFormError = (
  userSelection: ExtraSelectionType,
  termsAccepted: boolean,
  hasCurrentPlan: boolean,
  isFree?: boolean
): string => {
  let formError = '';
  if (
    hasCurrentPlan &&
    !userSelection.frameworks.length &&
    !userSelection.domains &&
    !userSelection.additionalService
  ) {
    formError = t('form.error.upgrade');
  } else if (!userSelection.frameworks.length && !isFree && !hasCurrentPlan) {
    formError = t('form.error.one_framework');
  } else if (!termsAccepted) {
    formError = t('form.error.accept_terms');
  }
  return formError;
};

export const generateFreemium = (productId: string, selectedFramework: string) => {
  return {
    productId,
    modifications: [
      {
        code: 'FRAMEWORKS',
        options: [selectedFramework],
      },
    ],
  };
};

export const generateOrder = (
  userSelection: ExtraSelectionType,
  productId: string,
  paymentInterval: string
): OrderProps => {
  const additionalService = userSelection.additionalService
    ? [
        {
          code: Modifications_Code.TRAINING,
          quantity: 1,
        },
      ]
    : [];
  const domains = userSelection.domains
    ? [
        {
          code: Modifications_Code.DOMAINS,
          quantity: userSelection.domains,
        },
      ]
    : [];
  const modifications = [
    {
      code: Modifications_Code.FRAMEWORKS,
      quantity: userSelection.frameworks.length,
      options: userSelection.frameworks.map((fw) => fw.toUpperCase()),
    },
    ...domains,
    ...additionalService,
  ];
  const cart: OrderCart = {
    productId,
    modifications,
  };
  const couponId = userSelection.discount?.id;
  if (couponId) {
    cart.couponId = couponId;
  }
  return {
    cart,
    paymentInterval,
    journey: [],
  };
};

export const generateUpgrade = (userSelection: ExtraSelectionType): UpgradeProps => {
  const additionalService = userSelection.additionalService
    ? [
        {
          code: Modifications_Code.TRAINING,
          quantity: 1,
        },
      ]
    : [];
  const domains = userSelection.domains
    ? [
        {
          code: Modifications_Code.DOMAINS,
          quantity: userSelection.domains,
        },
      ]
    : [];

  const frameworks = userSelection.frameworks.length
    ? [
        {
          code: Modifications_Code.FRAMEWORKS,
          quantity: userSelection.frameworks.length,
          options: userSelection.frameworks.map((fw) => fw.toUpperCase()),
        },
      ]
    : [];

  const modifications = [...frameworks, ...domains, ...additionalService];

  const cart: OrderCart = {
    modifications,
  };

  const couponId = userSelection.discount?.id;
  if (couponId) {
    cart.couponId = couponId;
  }

  return {
    cart,
  };
};

export const calcCouponDiscount = (
  couponData: CouponData,
  userSelection: ExtraSelectionType
): number => {
  let discount = couponData.discount.value;
  if (couponData.discount.unit === 'PERCENT') {
    discount = (userSelection.totalPrice.total * discount) / 100;
  }
  return discount;
};
