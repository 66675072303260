import styled from 'styled-components';

export const StyledWelcome = styled.div`
  cursor: unset;
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;

  span {
    color: ${(props) => props.theme.secondaryTextColor};
  }
`;

export const StyledDropdownButton = styled.a`
  display: inline-block;
`;

export const StyledUsernname = styled.span`
  font-weight: 700;
  font-size: 15px;
`;

export const StyledLoggedInText = styled.span`
  font-weight: 300;
  font-size: 12px;
`;

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.textColor};
`;

export const StyledUserName = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const StyledUserDetails = styled.div`
  color: ${(props) => props.theme.menuTextColor};
  display: flex;
  flex-direction: row;
  min-width: 200px;
  justify-content: ${(props) => (props.theme.isMobile ? 'flex-start' : 'space-between')};
  gap: 10px;

  ${(props) =>
    props.theme.isMobile &&
    `
  padding: 12px;
  border: 1px solid ${props.theme.secondBorder};
  border-radius: 5px;
  `}
`;

export const StyledEmail = styled.div`
  font-size: 16px;
  font-weight: 400;
`;
