import React from 'react';
import { Icon } from '@/shared/components/Icon';
import { SingleCheckbox } from './Checkbox';
import { CheckboxGroupProps } from './Checkbox.consts';
import { StyleldLabelMargin, StyledCheckboxGroup } from './Checkbox.styles';

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  onChange,
  options,
  icons,
  defaultValues,
  defaultValuesDisabled,
  $isVertical,
  dataTestId,
}: CheckboxGroupProps) => {
  return (
    <StyledCheckboxGroup
      onChange={onChange}
      defaultValue={defaultValues}
      data-testid={dataTestId}
      $isVertical={$isVertical}
    >
      {options.map((name, i) => {
        return (
          <StyleldLabelMargin key={name}>
            <SingleCheckbox
              value={name}
              defaultChecked={defaultValues?.includes(name)}
              defaultValueDisabled={defaultValuesDisabled}
              hideFromSR={true}
            />
            {icons && icons[i] && <Icon $src={icons[i]} $size={20} />}
          </StyleldLabelMargin>
        );
      })}
    </StyledCheckboxGroup>
  );
};
