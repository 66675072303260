import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { emailExp, fullNameExp } from '@/shared/regex';
import { StyledCol, StyledInput, StyledTextArea } from '@/shared/styles';
import { U1Button } from '@/shared/components/Button/U1Button';
import { StyledForm, StyledLabel } from '../Forms.styles';
import { FormProps } from '../Forms.consts';
import { useAxios } from '@/services/apiClient';
import { RES_STATUS } from '@/shared/consts';
import { StyledCodeError } from '@/Views/AfterLogin/Main/Client/Checkout/OrderSummary/OrderSummary.styles';
import { useTranslation } from 'react-i18next';

export const ContactUsForm: React.FC<FormProps> = ({ onOk }: FormProps) => {
  const [errorMsg, setErrorMsg] = useState('');
  const { t } = useTranslation();

  const [{ response: contactUsRes }, sendMessage] = useAxios(
    { method: 'post', url: 'support/contact-us' },
    { manual: true }
  );

  useEffect(() => {
    if (contactUsRes) {
      if (contactUsRes.status === RES_STATUS.CREATED && onOk) {
        onOk();
      } else {
        setErrorMsg(t('form.something_went_wrong'));
      }
    }
  }, [contactUsRes]);

  const onFinish = (values: any) => {
    sendMessage({
      data: {
        fullName: values.fullname.trim(),
        email: values.email.trim(),
        message: values.message,
      },
    });
  };

  return (
    <StyledForm $marginTop={'20px'} name="basic" onFinish={onFinish} autoComplete="off">
      {errorMsg && <StyledCodeError>{errorMsg}</StyledCodeError>}
      <StyledCol>
        <StyledLabel>{t('form.full_name')}</StyledLabel>
        <Form.Item
          name="fullname"
          rules={[
            {
              required: true,
              message: `${t('form.full_name')} ${t('form.required')}`,
              pattern: fullNameExp,
            },
          ]}
        >
          <StyledInput data-testid="nameField" placeholder={t('form.placeholder.name')} />
        </Form.Item>

        <StyledLabel>{t('form.email')}</StyledLabel>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: `${t('form.email')} ${t('form.required')}`,
              pattern: emailExp,
            },
          ]}
        >
          <StyledInput data-testid="emailField" placeholder={t('form.placeholder.email')} />
        </Form.Item>

        <StyledLabel>{t('form.message')}</StyledLabel>
        <Form.Item
          name="message"
          rules={[{ required: true, message: `${t('form.message')} ${t('form.required')}` }]}
        >
          <StyledTextArea data-testid="messageField" placeholder={t('form.placeholder.message')} />
        </Form.Item>
      </StyledCol>
      <Form.Item>
        <U1Button dataTestId="sendMessageButton" text={t('form.send_message')} htmlType="submit" />
      </Form.Item>
    </StyledForm>
  );
};
