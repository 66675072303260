import { SigninMethodesListItemProps } from '@/shared/props';
import { SignInMethodButton } from './SignInMethodButton';

export const SigninMethodesListItem: React.FC<SigninMethodesListItemProps> = ({
  signinMethodName,
  signinMethodLogoSrc,
  signinMethodTextColor,
}: SigninMethodesListItemProps) => {
  const url = `${import.meta.env.VITE_BASE_URL}/sso/${signinMethodName.toLowerCase()}`;

  return (
    <SignInMethodButton
      text={signinMethodName}
      $textColor={signinMethodTextColor}
      $logoSrc={signinMethodLogoSrc}
      $href={url}
    />
  );
};
