import { StyledLangSwitch } from './LangSwitch.styles';
import { SimpleSelect } from '@/shared/components/SimpleSelect/SimpleSelect';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalOutlined } from '@ant-design/icons';

export const LangSwitch: React.FC = () => {
  const langStore = useLangStore();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    langStore.getAllLanguages();
  }, []);

  const setLangInPath = (value: string) => {
    const selectedLang = langStore.getLanguage(value);
    const pathName = location.pathname;
    if (langStore.isLangSupported() && selectedLang) {
      const langPath = langStore.getSelectedLang();
      const newPath = pathName.replace(new RegExp(`(/${langPath})+`, 'g'), `/${selectedLang}`);
      navigate(`${newPath}`);
    } else {
      navigate(`${selectedLang}${pathName}`);
    }
    window.location.reload();
  };

  return (
    <StyledLangSwitch>
      <GlobalOutlined />
      <SimpleSelect
        label="select language"
        value={langStore.getSelectedLang().toUpperCase()}
        options={langStore.availableLangs}
        onSelect={(value: string) => setLangInPath(value)}
        showIcon={false}
      />
    </StyledLangSwitch>
  );
};
