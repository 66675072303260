import styled from 'styled-components';
import { StyledDashedBorderContainer, StyledRow, StyledCol, StyledText } from '@/shared/styles';
import { isMobile } from 'react-device-detect';
import { StyledMainBeforeLogin } from '../BeforeLogin.styles';

export const StyledCursor = styled.span<{ hideCursor: boolean }>`
  visibility: ${(props) => (props.hideCursor ? 'hidden' : 'visible')} !important;
`;

export const TypewriterContainer = styled.div`
  font-size: 28px;
  color: ${(props) => props.theme.textColor};
  margin-bottom: 40px;
  text-align: center;
  word-wrap: break-word;
  min-height: 85px;
`;

export const StyledSecondRow = styled(StyledRow)`
  flex-direction: row;
  justify-content: center;
`;

export const StyledHomepage = styled.div`
  color: ${(props) => props.theme.textColor};
  padding: ${(props) =>
    props.theme.isMobile ? '0px 0px 100px 0px' : '0px 0px 150px 0px'} !important;
  .type-wrapper {
    margin-bottom: 0.5em;
    font-weight: 600;
    line-height: 1.35;
  }
  .type-cursor,
  .type-wrapper {
    font-size: 30px;
  }

  .type-cursor {
    display: none;
  }
`;

export const BackgroundContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => (props.theme.isMobile ? '150' : '500')}px;

  @media only screen and (min-width: 2000px) {
    margin-bottom: 280px;
  }s
`;

export const StyledAppPreviewImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 55%;

  @media only screen and (max-width: 1250px) {
    width: 75%;
  }

  @media only screen and (max-width: 1000px) {
    width: 80%;
  }

  @media only screen and (max-width: 800px) {
    width: 85%;
  }

  img {
    width: 100%;
  }

  ${(props) => props.theme.isMobile && `top: 15px;`}
`;

export const StyledBenefitsCardsGrid = styled.div`
  ${(props) =>
    props.theme.isMobile
      ? `
    display: flex;
    flex-direction: column;
    gap: 16px;
    `
      : `
    display: grid;
    grid-template-columns: 300px 300px 300px;
    grid-template-rows: auto auto;
    gap: 32px 32px;
    grid-auto-flow: row;
    grid-template-areas:
    '. . .'
    '. . .';
    `}
`;

export const StyledBenefitsCard = styled.div`
  padding: ${(props) => (props.theme.isMobile ? '24px 15px' : '24px')};
  background: ${(props) => props.theme.marginsColor};
  border-radius: 5px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  display: grid;
  justify-content: center;
  align-content: center;
  line-height: 24px;
  width: ${(props) => (props.theme.isMobile ? '343' : '306')}px;
`;

export const StyledSectionContainer = styled(StyledCol)`
  align-items: center;
  gap: 42px;

  ${(props) =>
    props.theme.isMobile
      ? `
  width: 100%;`
      : `
  width: 1000px;`}
`;

export const StyledTopDescription = styled(StyledCol)`
  gap: 12px;
  align-items: center;
`;

export const StylecU1FeaturesExampleContainer = styled(StyledDashedBorderContainer)`
  padding: 32px;
  ${(props) => props.theme.isMobile && `width: 100%;`}
`;

export const StyledFrameWroksIconsRow = styled(StyledRow)`
  ${(props) =>
    props.theme.isMobile
      ? `
  gap: 10px;
      `
      : `
  width: 60%;
  justify-content: space-between;
  `}
`;

export const StyledIntro = styled(StyledCol)`
  text-align: center;
  align-items: center;

  ${(props) =>
    props.theme.isMobile &&
    `padding: 16px;
      h1{
        font-size: 20px !important;
      }

      h2 {
        font-size: 15px !important;
      }
  `}
`;

export const StyledFeatures = styled(StyledCol)`
  justify-content: center;
`;

export const StyledFeaturesContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.theme.isMobile ? 'column' : 'row')};
  gap: 52px;
`;

export const StyleBenefits = styled(StyledCol)`
  align-items: center;
  gap: 43px;
  ${isMobile && `margin-top: 100px;`}
`;

export const StyledMainSectionsText = styled(StyledText)`
  line-height: 30px;
  font-size: 20px !important;
  text-align: center;
`;

export const StyledTrustedImg = styled.img`
  width: 70%;
`;

export const StyledTrustedCompanies = styled(StyledRow)`
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;

  img {
    max-width: ${isMobile ? '70' : '100'}px;
    object-fit: contain;
  }
`;

export const StyledMainPage = styled(StyledMainBeforeLogin)`
  background-position: center ${isMobile ? '8%' : '40vh'};
  background-size: 100% auto;
`;
