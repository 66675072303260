import { SingleCheckbox } from '@/shared/components/Checkbox/Checkbox';
import { CheckboxGroup } from '@/shared/components/Checkbox/CheckboxGroup';
import { StyledInput } from '@/shared/styles';
import { getCapitalizedFrameworks, getFrameworkIcons, blockNanValues } from '@/shared/utils';
import { useSelectionStore, useExtraSelectionStore } from '@/stores/SelectionStore';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useState } from 'react';
import { getUpgradeDomainsPerFW } from '../../../UpgradePackage/UpgradePackage.utils';
import { UpgradePackageProps } from '../../../UpgradePackage/UpgradePackage.consts';
import { PackageSectionType } from '../../Checkout.consts';
import { getExtraDomainsPerFW } from '../../Checkout.utils';
import { ExtraSelection } from './ExtraSelection';
import { isMobile } from 'react-device-detect';
import { t } from 'i18next';

export const PackageExtraSelection: React.FC<UpgradePackageProps> = ({
  currentPlan,
}: UpgradePackageProps) => {
  const selectedPackage = useSelectionStore();
  const subscription = useSubscriptionStore();
  const userSelection = useExtraSelectionStore();
  const [currentPlanFW] = useState(getCapitalizedFrameworks(currentPlan?.frameworks));

  const onFrameworkSelected = (checkedValues: CheckboxValueType[]) => {
    const values = checkedValues as string[];
    const currrentSelection = values.filter((val) => !currentPlanFW?.includes(val));
    const newUserSelection = {
      ...userSelection,
      frameworks: currrentSelection.map((selection) => selection),
    };
    const domainsForExtraFw = currentPlan
      ? getUpgradeDomainsPerFW(newUserSelection, currentPlan)
      : getExtraDomainsPerFW(newUserSelection, selectedPackage);
    selectedPackage.setExtraDomains && selectedPackage.setExtraDomains(domainsForExtraFw);
    userSelection.setSelection && userSelection.setSelection(newUserSelection);
  };

  const changeDomains = (value: string) => {
    const newUserSelection = { ...userSelection, domains: Number(value) };
    userSelection.setSelection && userSelection.setSelection(newUserSelection);
  };

  const onServicesChecked = (checkboxEvent: CheckboxChangeEvent) => {
    const newUserSelection = {
      ...userSelection,
      additionalService: checkboxEvent.target.checked,
    };
    userSelection.setSelection && userSelection.setSelection(newUserSelection);
  };

  const packageSections: PackageSectionType[] = [
    {
      title: t('billing.upgarde_package.framework.title'),
      optional: false,
      content: t('billing.upgarde_package.framework.content'),
      info: t('billing.upgarde_package.framework.info'),
      extraComp: (
        <CheckboxGroup
          dataTestId="availableFrameworksBlock"
          onChange={onFrameworkSelected}
          options={
            selectedPackage.supportedFrameworks.length
              ? selectedPackage.supportedFrameworks.map((sf) => sf.title)
              : subscription.supportedFrameworks.map((sf) => sf.title)
          }
          icons={getFrameworkIcons(
            selectedPackage.supportedFrameworks.length
              ? selectedPackage.supportedFrameworks
              : subscription.supportedFrameworks
          )}
          defaultValues={currentPlanFW ?? userSelection.frameworks ?? []}
          defaultValuesDisabled={!!currentPlan}
        />
      ),
    },
    {
      title: t('billing.upgarde_package.domains.title'),
      optional: true,
      content: t('billing.upgarde_package.domains.content'),
      extraComp: (
        <StyledInput
          data-testid="additionalDomainsField"
          $width={isMobile ? '100%' : '60%'}
          $height={'45px'}
          placeholder={t('billing.upgarde_package.domains.placeholder')}
          onChange={(e) => changeDomains(e.target.value)}
          onKeyDown={(e) => blockNanValues(e)}
          type={'text'}
          value={userSelection.domains > 0 ? userSelection.domains : ''}
          maxLength={10}
        />
      ),
    },
    {
      title: t('billing.upgarde_package.additional_services.title'),
      optional: true,
      content: t('billing.upgarde_package.additional_services.content'),
      info: t('billing.upgarde_package.additional_services.info'),
      extraComp: (
        <SingleCheckbox
          dataTestId="trainingAndAuditingCheckbox"
          value={t('billing.upgarde_package.additional_services_20h.title')}
          onChange={onServicesChecked}
          defaultChecked={currentPlan?.additionalServices ?? userSelection.additionalService}
          defaultValueDisabled={!!currentPlan}
          hideFromSR={true}
        />
      ),
    },
  ];

  return <ExtraSelection extras={packageSections} />;
};
